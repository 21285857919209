













































































import useMisc from "@/use/misc";
import { defineComponent, reactive, watch } from "@vue/composition-api";

export default defineComponent({
  props: {
    value: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  setup(props, { root }) {
    const { paymentStatus } = useMisc({ root });

    const state = reactive({
      paymentDate: {
        from: "",
        to: "",
      },
      invoiceStatus: false,
    });

    watch(
      () => state.paymentDate,
      () => {
        if (state.paymentDate.from || state.paymentDate.to) {
          props.value.paymentDate.values = [
            state.paymentDate.from ? state.paymentDate.from : undefined,
            state.paymentDate.to ? state.paymentDate.to : undefined,
          ];
        } else props.value.paymentDate.values = [];
      },
      { deep: true }
    );

    watch(
      () => props.value.invoiceStatus.values,
      () => {
        if (props.value.invoiceStatus.values === "no") {
          props.value.invoiceStatus.operation = "isNull";
        } else {
          props.value.invoiceStatus.operation = "isNotNull";
        }
      }
    );

    return { paymentStatus, state };
  },
});
